<template>
    <v-container id="customerCare" v-if="bolletta_interattiva_duferco_customercare">
      <v-spacer class="spacerExpPanelUp"></v-spacer>
        <v-expansion-panels flat>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <template v-slot:actions>
                            <v-icon color="white">
                            mdi-menu-down
                            </v-icon>
                        </template>
                    <h1 style="display:flex;align-items: center;"><img v-if="true" :src="getCustomerCareLogo()" id="customerCareLogo" class="pr-3"/>{{$t('message.bolletta_interattiva_duferco_customercare_header')}}</h1>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row justify="center">
                        <v-col cols="12" xl="8" lg="8" md="12" sm="12">
                            <p id="customerCareText" class="pt-3" align="center">{{ $t('message.bolletta_interattiva_duferco_customercare_text') }}</p>
                        </v-col>                         
                        <v-row justify="center">
            
                <v-col xl="5" lg="5" md="5" sm="5"></v-col>
                <v-col xl="2" lg="2" md="2" sm="2" align-center>
                    <v-btn
                    @click="sendCustomerCareMessage()"
                    style="border-radius: 8px !important;"
                    class="black--text"                          
                    >
                    {{$t('message.bolletta_interattiva_duferco_customercare_invia')}}          
                    </v-btn>
                </v-col>
                <v-col xl="5" lg="5" md="5" sm="5"></v-col>
            </v-row>
                    </v-row>
                    <v-row>
                        <v-col xl="5" lg="5" md="5" sm="5"></v-col>
                        <v-col xl="2" lg="2" md="2" sm="2" align-center>
                            <v-dialog v-model="showDialog" width="500">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                    v-bind="attrs" v-on="on"
                                    :disabled="!($refs.customerCareForm.validate()&&fileInputValidate)"
                                    @click="showDialog = true"
                                    style="border-radius: 8px !important;"
                                    class="black--text"                   
                                    >
                                    {{$t('message.bolletta_interattiva_duferco_customercare_invia')}}          
                                    </v-btn>
                                </template>

                                <v-card id="confirm-dialog"> 
                                    <v-card-text class="text-subtitle-1 pa-5"> {{$t('message.bolletta_interattiva_duferco_customercare_conferma_invia')}} </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn text @click="showDialog = false"> {{$t('message.bolletta_interattiva_duferco_customercare_annulla')}} </v-btn>
                                        <v-btn text @click="sendCustomerCareMessage()"> {{$t('message.bolletta_interattiva_duferco_customercare_invia')}} </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                        <v-col xl="5" lg="5" md="5" sm="5"></v-col>
                    </v-row>
                    <div v-if="showCustomerCareInvioSuccess" align="center" id="customerCareSuccessText" class="pt-3">{{$t('message.bolletta_interattiva_duferco_customercare_invio_ok')}}</div>
                    <div v-if="showSaveError" align="center" id="customerCareErrorText" class="pt-3">{{$t('message.bolletta_interattiva_duferco_customercare_invio_ko')}}</div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
      <v-spacer class="spacerExpPanelDown"></v-spacer>
    </v-container>
</template>

<script>

export default {
    name: 'customerCare',
    data: function() {
        return {
            bolletta_interattiva_duferco_customercare : true,   
        }
    },
    props: {
        documentData: {
            type: Object,
            default: null
        },
        checkLabel: {
            type: Function,
        },
    },
    computed: {
    },
    components: {
        
    },
    methods: {
        getCustomerCareLogo: function () {
            return "./" + location.hostname + "/images/bollettainterattiva/customerCare.png";
        },
        sendCustomerCareMessage: function () {
          window.location.href = "mailto:clienti@dufercoenergia.com"
        },
    },
	created: function () {
		this.bolletta_interattiva_duferco_customercare = Boolean(Number(this.documentData.bolletta_interattiva_duferco_customercare));
	}
}
</script>
<style scoped>
.v-text-field >>> fieldset {
  border-color: rgba(255, 255, 255, 0.986)!important;
  color: rgba(255, 255, 255, 0.986)!important;
}
.v-text-field >>> .v-text-field__slot input {
  color:  white !important;
}
.v-text-field >>> .v-select__slot input {
  color:  white !important;
}
.v-text-field >>> .v-select__slot label  {
  color:  white !important;
}
.v-text-field >>> .v-text-field__slot label  {
  color:  white !important;
}
.v-text-field >>> .v-icon  {
  color:  white !important;
}
.v-text-field >>>  textarea  {
  color:  white !important;
}


</style>
  