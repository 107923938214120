<template>
    <v-container id="BollettaInterattivaCustomerCare3Dt" v-if="bolletta_interattiva_duferco_customercare">
        <div>
            <p class="text-h5 HeaderMultiComponent">{{$t('message.bolletta_interattiva_duferco_customercare_header')}}</p>
        </div>    
            <v-row justify="center">
                <v-col cols="12" >
                    <p id="customerCareText" class="pt-3">{{ $t('message.bolletta_interattiva_duferco_customercare_text') }}</p>
                </v-col>                         
            </v-row>
            <v-row>
                <v-col xl="5" lg="5" md="5" sm="5"></v-col>
                <v-col xl="2" lg="2" md="2" sm="2" align-center>
                    <v-btn
                    @click="sendCustomerCareMessage()"
                    style="border-radius: 8px !important;"
                    class="black--text"                          
                    >
                    {{$t('message.bolletta_interattiva_duferco_customercare_invia')}}          
                    </v-btn>
                </v-col>
                <v-col xl="5" lg="5" md="5" sm="5"></v-col>
            </v-row>
    </v-container>
</template>

<script>

export default {
    name: 'customerCare',
    data: function() {
        return {
            bolletta_interattiva_duferco_customercare : true,   
        }
    },
    props: {
        documentData: {
            type: Object,
            default: null
        },
        checkLabel: {
            type: Function,
        },
    },
    methods: {
        sendCustomerCareMessage: function () {
          window.location.href = "mailto:clienti@dufercoenergia.com"
        },
    },
	created: function () {
		this.bolletta_interattiva_duferco_customercare = Boolean(Number(this.documentData.bolletta_interattiva_duferco_customercare));
	}
}
</script>
<style scoped>
.v-text-field >>> fieldset {
  border-color: rgba(255, 255, 255, 0.986)!important;
  color: rgba(255, 255, 255, 0.986)!important;
}
.v-text-field >>> .v-text-field__slot input {
  color:  white !important;
}
.v-text-field >>> .v-select__slot input {
  color:  white !important;
}
.v-text-field >>> .v-select__slot label  {
  color:  white !important;
}
.v-text-field >>> .v-text-field__slot label  {
  color:  white !important;
}
.v-text-field >>> .v-icon  {
  color:  white !important;
}
.v-text-field >>>  textarea  {
  color:  white !important;
}


</style>
  